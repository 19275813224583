import { ILanguage } from '../../language';

export const LANG_Footer: ILanguage[] = [
    {
        code: 'footer_legal',
        base: 'legal',
        translation: 'Legal Disclosure',
    },
    {
        code: 'footer_terms',
        base: 'terms',
        translation: 'GTC',
    },
    {
        code: 'footer_privacy',
        base: 'privacy',
        translation: 'Privacy statement',
    },
];
