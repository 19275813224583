import React, { FC } from 'react';
import { Buttons_Language, BUTTONS_LANGUAGE } from '../language/german/Lang_Buttons';
import { Footer_Language, FOOTER_LANGUAGE } from '../language/german/structure/lang_Footer';
import { Header_Language, HEADER_LANGUAGE } from '../language/german/structure/lang_Header';

// import { Account_Language, ACCOUNT_LANGUAGE } from '../language/german/views/lang_Account';

export interface ILanguageContext {
    // account: Account_Language;
    footer: Footer_Language;

    header: Header_Language;
    buttons: Buttons_Language;
}

export const defaultGlobalLanguageState: ILanguageContext = {
    // account: ACCOUNT_LANGUAGE,
    footer: FOOTER_LANGUAGE,
    header: HEADER_LANGUAGE,
    buttons: BUTTONS_LANGUAGE,
};

export const GlobalLanguageCxt = React.createContext<ILanguageContext>(defaultGlobalLanguageState);

export const GlobalLanguageContext: FC = ({ children }) => {
    // const [languageSets] = useState(defaultGlobalLanguageState.languageSets);
    return (
        <GlobalLanguageCxt.Provider
            value={{
                ...defaultGlobalLanguageState,
            }}
        >
            {children}
        </GlobalLanguageCxt.Provider>
    );
};
