import { LanguageBaseSet, LgText } from 'ttmultilanguage';
import { LANGUAGE_MANAGER } from '../../language';
export class Footer_Language extends LanguageBaseSet {
    _languageCode = 'de';
    legal = LgText('Impressum');
    terms = LgText('AGB');
    privacy = LgText('Datenschutz');
}

export const FOOTER_LANGUAGE = Footer_Language.createInstance();
if (!LANGUAGE_MANAGER.has('footer')) LANGUAGE_MANAGER.add('footer', FOOTER_LANGUAGE);
