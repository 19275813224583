import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { Central } from 'reactextensions';
import { GlobalCxt, IContext } from './context/GlobalContext';
import './scss/main.scss';
import Body from './strucure/Body';
import { Footer } from './strucure/Footer';
import { Header } from './strucure/Header';
interface Props {}
export default function App(props: Props) {
    const { keycloakContext }: IContext = React.useContext(GlobalCxt);

    return (
        <div className="bodyContainer">
            <Central />

            {keycloakContext.keycloak.authenticated ? (
                <div className="container_app">
                    <Header projectTitle="ttApp" />
                    <Body />
                    <Footer />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
