import { LanguageBaseSet, LgText } from 'ttmultilanguage';
import { LANGUAGE_MANAGER } from '../language';
export class Buttons_Language extends LanguageBaseSet {
    _languageCode = 'de';
    cancel = LgText('Abbrechen');
    confirm = LgText('Bestätigen');
    save_changes = LgText('Änderungen speichern');
    reset_password = LgText('Passwort zurücksetzen');
    search = LgText('Suche');
    buy = LgText('Kaufen');
    addClient = LgText('Applikation hinzufügen');
    editClient = LgText('Applikation ändern');
    addUser = LgText('Benutzer hinzufügen');
    editUser = LgText('Benutzer ändern');
    add = LgText('Anlegen');
    edit = LgText('Ändern');
    delete = LgText('Löschen');
    goToApp = LgText('Applikation öffnen');
}

export const BUTTONS_LANGUAGE = Buttons_Language.createInstance();
if (!LANGUAGE_MANAGER.has('buttons')) LANGUAGE_MANAGER.add('buttons', BUTTONS_LANGUAGE);
