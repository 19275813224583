import { typedGet, typedPost } from 'ttroutertools_client';
import { IRoute_Stats_Shorten_Url, IRoute_Stats_Used_Shorten_Url } from '../../../backend/src/routes/stats/callInterface';

export async function getUrlStats(url: string, token: string) {
    return typedGet<IRoute_Stats_Shorten_Url>({
        url,
        token,
        route: '/stats/url',
    });
}

export async function getUsedUrlStats(url: string, token: string) {
    return typedGet<IRoute_Stats_Used_Shorten_Url>({
        url,
        token,
        route: '/stats/url/used',
    });
}
