import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

interface Props {
    change: (val: number) => void;
    isRequired?: boolean;
    readOnly: boolean;
    title: string;
    value: number;
    maxValue?: number;
    minValue?: number;
}

interface State {
    isInvalid: boolean;
    readOnly: boolean;
}

/**
 *
 *
 * @class NumberControl
 * @extends {React.Component<Props, State>}
 * @description Component for an inline formatted input element exclusively for numbers with a label
 */
class NumberControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isInvalid: props.value === 0,
            readOnly: props.readOnly,
        };
    }

    public componentDidUpdate(): any {
        if (this.props.readOnly !== this.state.readOnly) {
            this.setState({
                readOnly: this.props.readOnly,
            });
        }
        return null;
    }

    public render(): JSX.Element {
        return (
            <Form.Group as={Row}>
                <Form.Label column sm="3" className="launcher_form_label">
                    {this.props.title}
                </Form.Label>
                <Col sm={9}>
                    <Form.Control
                        readOnly={this.state.readOnly}
                        type="text"
                        value={this.props.value}
                        onChange={(ev) => {
                            let value = ev.currentTarget.value;
                            if (!isNaN(Number.parseInt(value))) {
                                if (this.props.minValue ? this.props.minValue > Number.parseInt(value) : null) return;
                                if (this.props.maxValue ? this.props.maxValue < Number.parseInt(value) : null) return;
                                this.props.change(Number.parseInt(value));
                                this.setState({ isInvalid: ev.currentTarget.value === '' });
                            }
                            if (value === '') {
                                this.props.change(0);
                                if (!this.props.isRequired) this.setState({ isInvalid: ev.currentTarget.value === '' });
                            }
                        }}
                        isInvalid={!this.props.isRequired ? false : this.state.isInvalid}
                    />
                </Col>
            </Form.Group>
        );
    }
}

export default NumberControl;
