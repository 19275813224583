import { LanguageBaseSet, LgText } from 'ttmultilanguage';
import { LANGUAGE_MANAGER } from '../../language';
export class Header_Language extends LanguageBaseSet {
    _languageCode = 'de';
    logout = LgText('Ausloggen');
    userStatus = LgText('Benutzer:');
}

export const HEADER_LANGUAGE = Header_Language.createInstance();
if (!LANGUAGE_MANAGER.has('header')) LANGUAGE_MANAGER.add('header', HEADER_LANGUAGE);
