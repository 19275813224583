import Keycloak from 'keycloak-js';
import { Language } from '../language/language';
import { CSSDesign } from '../context/GlobalAccountSettingsContext';

export interface TribeUserPreferrences {
    language: string;
    design: string;
}

export const getDesignFromPreferrences = (
    tokenParsed: Keycloak.KeycloakTokenParsed | undefined,
    defaultValue: CSSDesign = 'ttunited'
): CSSDesign => {
    const account_preferrences: TribeUserPreferrences = tokenParsed?.preferrences || { design: 'ttunited', language: 'de' };
    switch (account_preferrences.design) {
        case 'ttunited':
            return account_preferrences.design;
        case 'darkmode':
            return account_preferrences.design;
    }
    return defaultValue;
};

/**
 * Function to read the last current language, if for example the app is closed
 *
 * @export
 */
export const getLanguageFromPreferrences = (
    tokenParsed: Keycloak.KeycloakTokenParsed | undefined,
    defaultValue: Language = 'de'
): Language => {
    const account_preferrences: TribeUserPreferrences = tokenParsed?.preferrences || { design: 'ttunited', language: 'de' };
    switch (account_preferrences.language) {
        case 'de':
            return 'de';
        case 'en':
            return 'en';
    }
    return defaultValue;
};

export const getAccountPreferrence = (tokenParsed: Keycloak.KeycloakTokenParsed | undefined): TribeUserPreferrences => {
    const account_preferrences: TribeUserPreferrences = tokenParsed?.preferrences || { design: 'ttunited', language: 'de' };
    return account_preferrences;
};
