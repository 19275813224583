import React from 'react';
import { Form } from 'react-bootstrap';
interface Props {
    title: string;
    value: boolean;
    readOnly: boolean;
    change: (val: boolean) => void;
}
/**
 *
 *
 * @class CheckBoxControl
 * @extends {React.Component<Props>}
 * @param title string String which is displayed in the label
 * @param value boolean Value of the button to be displayed
 * @param readOnly boolean Blocks change of values
 * @param change (val: boolean) => void SetState function for the value
 * @description Component for an inline formatted Chechbkox element with a label
 */
class CheckBoxControl extends React.Component<Props> {
    render(): JSX.Element {
        return (
            <>
                <Form.Group>
                    <Form.Check
                        className="launcher_form_label"
                        label={this.props.title}
                        readOnly={this.props.readOnly}
                        type="checkbox"
                        checked={this.props.value}
                        onChange={(ev) => {
                            this.props.change(ev.currentTarget.checked);
                        }}
                    />
                </Form.Group>
            </>
        );
    }
}

export default CheckBoxControl;
