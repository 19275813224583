import { typedPost } from 'ttroutertools_client';
import {
    IRoute_Shorten_Data,
    IRoute_Shorten_Flipbook,
    IRoute_Shorten_Url,
    IRoute_Shorten_Youtube,
    IRoute_Shorten__Encrypted_Data,
    IRoute_Shorten__Encrypted_Url,
} from '../../../backend/src/routes/shortener/callInterface';

export async function shortenUrl(url: string, token: string, body: IRoute_Shorten_Url['body']) {
    return typedPost<IRoute_Shorten_Url>({
        url,
        token,
        route: '/shorten/url',
        body,
    });
}

export async function shortenData(url: string, token: string, body: IRoute_Shorten_Data['body']) {
    return typedPost<IRoute_Shorten_Data>({
        url,
        token,
        route: '/shorten/data',
        body,
    });
}

export async function shortenEncryptedUrl(url: string, token: string, body: IRoute_Shorten__Encrypted_Url['body']) {
    return typedPost<IRoute_Shorten__Encrypted_Url>({
        url,
        token,
        route: '/shorten/encrypted-url',
        body,
    });
}

export async function shortenEncryptedData(url: string, token: string, body: IRoute_Shorten__Encrypted_Data['body']) {
    return typedPost<IRoute_Shorten__Encrypted_Data>({
        url,
        token,
        route: '/shorten/encrypted-data',
        body,
    });
}

export async function shortenFlipbook(url: string, token: string, body: IRoute_Shorten_Flipbook['body']) {
    return typedPost<IRoute_Shorten_Flipbook>({
        url,
        token,
        route: '/shorten/flipbook',
        body,
    });
}

export async function shortenYoutube(url: string, token: string, body: IRoute_Shorten_Youtube['body']) {
    return typedPost<IRoute_Shorten_Youtube>({
        url,
        token,
        route: '/shorten/youtube',
        body,
    });
}
