import React, { useState, FC } from 'react';
import { Language, LANGUAGE_MANAGER } from '../language/language';

/**
 * Function to read the last current design, if for example the app is closed
 *
 * @param {CSSDesign} [defaultValue="ttunited"]
 * @returns {CSSDesign}
 */
export const getDesignFromLocalStorage = (defaultValue: CSSDesign = 'ttunited'): CSSDesign => {
    const design = localStorage.getItem('currentCssDesign');
    switch (design) {
        case 'ttunited':
            return design;
        case 'darkmode':
            return design;
    }
    return defaultValue;
};

/**
 * Function to read the last current language, if for example the app is closed
 *
 * @export
 */
export const getLanguageFromLocalStorage = (defaultValue: Language = 'de'): Language => {
    const design = localStorage.getItem('currentLanguage');
    switch (design) {
        case 'de':
            return 'de';
        case 'en':
            return 'en';
    }
    return defaultValue;
};

export type CSSDesign = 'ttunited' | 'darkmode';

export interface IAccountSettingsContext {
    currentCssDesign: CSSDesign;
    setCssDesign: (design: CSSDesign) => void;
    currentLanguage: Language;
    setLanguage: (language: Language) => void;
}

export const defaultAccountSettingsState: IAccountSettingsContext = {
    currentCssDesign: getDesignFromLocalStorage('ttunited'),
    setCssDesign: (design: CSSDesign) => {},
    currentLanguage: getLanguageFromLocalStorage('de'),
    setLanguage: (language: Language) => {},
};

export const GlobalAccountSettingsCxt = React.createContext<IAccountSettingsContext>(defaultAccountSettingsState);

export const GlobalAccountSettingsContext: FC = ({ children }) => {
    const [currentCssDesign, setCssDesignState] = useState(defaultAccountSettingsState.currentCssDesign);
    const [currentLanguage, setLanguageState] = useState(defaultAccountSettingsState.currentLanguage);
    const setCssDesign = (design: CSSDesign) => {
        document.body.classList.remove(currentCssDesign);
        document.body.classList.add(design);
        localStorage.setItem('currentCssDesign', design);
        setCssDesignState(design);
    };

    const setLanguage = (language: Language) => {
        localStorage.setItem('currentLanguage', language);
        LANGUAGE_MANAGER.activate((('&' + ('' + window.location.search + '&').substr(1)).match(/\&lang=([^\&]+)\&/) || ['', language])[1]);
        setLanguageState(language);
    };

    return (
        <GlobalAccountSettingsCxt.Provider
            value={{
                currentCssDesign,
                setCssDesign,
                currentLanguage,
                setLanguage,
            }}
        >
            {children}
        </GlobalAccountSettingsCxt.Provider>
    );
};
