import React from 'react';
import { Col } from 'react-bootstrap';
import { GlobalCxt, IContext } from '../context/GlobalContext';

/**
 * Footer with links to ttunited.com
 *
 * @return {JSX.Element}
 */
export const Footer = (): JSX.Element => {
    const { languageContext }: IContext = React.useContext(GlobalCxt);

    return (
        <footer className="row row-cols-9 py-3 footer app_footer">
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col>
                <a className="nav-link p-0" href="https://www.ttunited.com/impressum" target="_blank" rel="noopener noreferrer">
                    {languageContext.footer.legal.text}
                </a>
            </Col>
            <Col>
                <a className="nav-link p-0" href="https://www.ttunited.com/agb" target="_blank" rel="noopener noreferrer">
                    {languageContext.footer.terms.text}
                </a>
            </Col>
            <Col>
                <a className="nav-link p-0" href="https://www.ttunited.com/datenschutz" target="_blank" rel="noopener noreferrer">
                    {languageContext.footer.privacy.text}
                </a>
            </Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
        </footer>
    );
};
