import React from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

export interface TableColumnHeader {
    label: string;
    filter?: { fnc: (val: string) => void; value?: string };
    sort?: () => void;
}

interface Props {
    headerLabels: TableColumnHeader[];
    rows: JSX.Element[][];
}
export default function TurboTable(props: Props) {
    const buildTableHeader = () => {
        return (
            <tr className="quicktable_header">
                {props.headerLabels.map((c, cix) => {
                    if (c.filter)
                        return (
                            <React.Fragment key={cix}>
                                <th>
                                    <Row>
                                        <Col style={{ display: 'inline-flex' }}>
                                            <Form.Control
                                                style={{ width: 'auto' }}
                                                placeholder={c.label}
                                                value={c.filter.value || ''}
                                                onChange={(ev) => c.filter!.fnc(ev.currentTarget.value)}
                                            ></Form.Control>
                                            {sortElement(c.sort)}
                                        </Col>
                                    </Row>
                                </th>
                            </React.Fragment>
                        );
                    else
                        return (
                            <th key={c.label}>
                                <Row>
                                    <Col style={{ display: 'inline-flex' }}>
                                        {c.label}
                                        {sortElement(c.sort)}
                                    </Col>
                                </Row>
                            </th>
                        );
                })}
            </tr>
        );
    };

    const sortElement = (sort?: () => void) => {
        if (sort)
            return (
                <Button
                    onClick={() => {
                        sort!();
                    }}
                >
                    Sort
                </Button>
            );
        else return <></>;
    };

    const buildTableBody = () => {
        if (props.rows)
            return props.rows.map((e, ix) => {
                return (
                    <tr key={ix} className={ix % 2 === 0 ? 'quicktable_row_even' : 'quicktable_row_odd'}>
                        {e.map((e, eix) => (
                            <td key={eix}>{e}</td>
                        ))}
                    </tr>
                );
            });
        else return <tr className="quicktable_row_even"></tr>;
    };
    const buildTableFooter = () => {
        return (
            <tr>
                <td className="quicktable_footer"></td>
                {new Array(props.headerLabels.length - 2).fill(<></>).map((e, ix) => (
                    <td key={ix} style={{ border: 'none' }}></td>
                ))}
            </tr>
        );
    };

    return (
        <Table>
            <thead>{buildTableHeader()}</thead>
            <tbody>{buildTableBody()}</tbody>
            <tfoot>{buildTableFooter()}</tfoot>
        </Table>
    );
}
