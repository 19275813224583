import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';

import { GlobalCxt, IContext } from '../context/GlobalContext';
import TextInputControl from '../components/inputElements/TextInputControl';
import NumberControl from '../components/inputElements/NumberControl';
import { shortenData, shortenEncryptedData, shortenEncryptedUrl, shortenUrl, shortenYoutube } from '../api/shortener';
import { IRoute_Shorten_Flipbook, IRoute_Shorten_Youtube } from '../../../backend/src/routes/shortener/callInterface';
import CheckBoxControl from '../components/inputElements/CheckBoxControl';
interface Props {}
export default function Dashboard() {
    const { keycloakContext, stateContext }: IContext = React.useContext(GlobalCxt);
    const [url, setUrl] = useState('https://www.ttunited.com/');
    const [id, setID] = useState('');
    const [data, setData] = useState('Hallo');
    const [tracking_id, setTrackingID] = useState('');

    const [encryptedUrl, setEncryptedUrl] = useState('https://www.ttunited.com/');
    const [encryptedData, setEncryptedData] = useState('Hallo');
    const [flipBook, setFlipBook] = useState<
        Omit<
            IRoute_Shorten_Flipbook['body'],
            'short_id' | 'live_on' | 'expires_on' | 'lifespan' | 'uses' | 'url_type' | 'redirect' | 'tracking_id' | 'save_tracking'
        >
    >({
        title: '',
        table_of_contents: '',
        background_path: '',
        content_path: '',
        settings_js: '',
        settings_override_js: '',
    });
    const [youtube, setYoutube] = useState<
        Omit<
            IRoute_Shorten_Youtube['body'],
            'short_id' | 'live_on' | 'expires_on' | 'lifespan' | 'uses' | 'url_type' | 'redirect' | 'tracking_id' | 'save_tracking'
        >
    >({
        video_id: 'dQw4w9WgXcQ',
        page_title: 'Tutorial',
        css_style: '',
        company_logo_path: '',
        header_html: '<h3>Header<h3/>',
        footer_html: '<h3>Footer<h3/>',
        player_width: 640,
        player_height: 400,
        comment: '',
    });
    const [lifespanInS, setLifespanInS] = useState(0);
    const [uses, setUses] = useState(5);
    const [liveOn, setLiveOn] = useState('');
    const [expireOn, setExpireOn] = useState('');
    const [redirect, setRedirect] = useState(true);
    const [saveTracking, setSaveTracking] = useState(true);

    const [resultUrl, setResultUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [key, setKey] = useState('url');
    const update = async () => {};
    useEffect(() => {
        (async () => {
            await update();
        })();
    }, []);
    return (
        <Container>
            {errorMessage !== '' ? (
                <Row>
                    <Col sm="2">
                        <h4>Error Message:</h4>
                    </Col>
                    <Col>
                        <p>{errorMessage}</p>
                    </Col>
                </Row>
            ) : (
                <></>
            )}
            <Row>
                <Col>
                    {' '}
                    <Card>
                        <Card.Header>Url Configuration:</Card.Header>
                        <Card.Body>
                            <Row>
                                <TextInputControl
                                    title={'Set a specific Url Ending:'}
                                    placeHolder={''}
                                    readOnly={false}
                                    change={setID}
                                    value={id}
                                />
                            </Row>
                            <Row>
                                <label>Date to be switched live (0 for now)</label>
                                <input type="datetime-local" value={liveOn} onChange={(ev) => setLiveOn(ev.currentTarget.value)} />
                            </Row>
                            <Row>
                                <label>Date to expire (0 for never)</label>
                                <input type="datetime-local" value={expireOn} onChange={(ev) => setExpireOn(ev.currentTarget.value)} />
                            </Row>
                            <Row>
                                <NumberControl
                                    change={setLifespanInS}
                                    readOnly={false}
                                    title={'Lifespan in S (0 for no lifespan)'}
                                    value={lifespanInS}
                                />
                            </Row>
                            <Row>
                                <NumberControl change={setUses} readOnly={false} title={'Uses (0 for no limit)'} value={uses} />
                            </Row>
                            <Row>
                                <TextInputControl
                                    title={'Tracking ID:'}
                                    placeHolder={''}
                                    readOnly={false}
                                    change={setTrackingID}
                                    value={tracking_id}
                                />
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Row>
                        <Card>
                            <Card.Header>Url Type</Card.Header>
                            <Card.Body>
                                <Tabs activeKey={key} onSelect={(k) => setKey(k || '')}>
                                    <Tab eventKey="url" title="Url">
                                        <Row>
                                            <TextInputControl
                                                title={'Url:'}
                                                placeHolder={''}
                                                readOnly={false}
                                                change={setUrl}
                                                value={url}
                                            />
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CheckBoxControl title="Redirect" readOnly={false} value={redirect} change={setRedirect} />
                                            </Col>
                                            <Col>
                                                <CheckBoxControl
                                                    title="Save Tracking"
                                                    readOnly={false}
                                                    value={saveTracking}
                                                    change={setSaveTracking}
                                                />
                                            </Col>{' '}
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button
                                                    onClick={async () => {
                                                        let result = await shortenUrl(
                                                            stateContext.apiurl,
                                                            keycloakContext.keycloak.token ? keycloakContext.keycloak.token : 'dummy_token',
                                                            {
                                                                lifespan: lifespanInS,
                                                                url,
                                                                uses,
                                                                live_on: liveOn,
                                                                expires_on: expireOn,
                                                                id: id === '' ? undefined : id,
                                                                redirect,
                                                                tracking_id,
                                                                save_tracking: saveTracking,
                                                            }
                                                        );
                                                        if (result) {
                                                            if ('error' in result) {
                                                                setErrorMessage(result.error);
                                                            } else {
                                                                setResultUrl(result.url);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    Send
                                                </Button>
                                            </Col>
                                            <Col></Col>
                                            <Col></Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="data" title="Data">
                                        <TextInputControl title={'Data:'} placeHolder={''} readOnly={false} change={setData} value={data} />
                                        <Row>
                                            <Col>
                                                <Button
                                                    onClick={async () => {
                                                        let result = await shortenData(
                                                            stateContext.apiurl,
                                                            keycloakContext.keycloak.token ? keycloakContext.keycloak.token : 'dummy_token',
                                                            {
                                                                lifespan: lifespanInS,
                                                                data,
                                                                uses,
                                                                live_on: liveOn,
                                                                expires_on: expireOn,
                                                                id: id === '' ? undefined : id,
                                                                redirect: false,
                                                                tracking_id,
                                                                save_tracking: saveTracking,
                                                            }
                                                        );
                                                        if (result) {
                                                            if ('error' in result) {
                                                                setErrorMessage(result.error);
                                                            } else {
                                                                setResultUrl(result.url);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    Send
                                                </Button>
                                            </Col>
                                            <Col></Col>
                                            <Col></Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="encrypted_url" title="Encrypted Url">
                                        <Row>
                                            <TextInputControl
                                                title={'Encrypted Url:'}
                                                placeHolder={''}
                                                readOnly={false}
                                                change={setEncryptedUrl}
                                                value={encryptedUrl}
                                            />
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CheckBoxControl title="Redirect" readOnly={false} value={redirect} change={setRedirect} />
                                            </Col>
                                            <Col>
                                                <CheckBoxControl
                                                    title="Save Tracking"
                                                    readOnly={false}
                                                    value={saveTracking}
                                                    change={setSaveTracking}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button
                                                    onClick={async () => {
                                                        let result = await shortenEncryptedUrl(
                                                            stateContext.apiurl,
                                                            keycloakContext.keycloak.token ? keycloakContext.keycloak.token : 'dummy_token',
                                                            {
                                                                lifespan: lifespanInS,
                                                                url: encryptedUrl,
                                                                uses,
                                                                live_on: liveOn,
                                                                expires_on: expireOn,
                                                                redirect,
                                                                tracking_id,
                                                                save_tracking: saveTracking,
                                                            }
                                                        );
                                                        if (result) {
                                                            if ('error' in result) {
                                                                setErrorMessage(result.error);
                                                            } else {
                                                                setResultUrl(result.url);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    Send
                                                </Button>
                                            </Col>
                                            <Col></Col>
                                            <Col></Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="encrypted_data" title="Encrypted Data">
                                        <TextInputControl
                                            title={'Encrypted Data:'}
                                            placeHolder={''}
                                            readOnly={false}
                                            change={setEncryptedData}
                                            value={encryptedData}
                                        />
                                        <Row>
                                            <Col>
                                                <Button
                                                    onClick={async () => {
                                                        let result = await shortenEncryptedData(
                                                            stateContext.apiurl,
                                                            keycloakContext.keycloak.token ? keycloakContext.keycloak.token : 'dummy_token',
                                                            {
                                                                lifespan: lifespanInS,
                                                                data: encryptedData,
                                                                uses,
                                                                live_on: liveOn,
                                                                expires_on: expireOn,
                                                                redirect: false,
                                                                tracking_id,
                                                                save_tracking: saveTracking,
                                                            }
                                                        );
                                                        if (result) {
                                                            if ('error' in result) {
                                                                setErrorMessage(result.error);
                                                            } else {
                                                                setResultUrl(result.url);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    Send
                                                </Button>
                                            </Col>
                                            <Col></Col>
                                            <Col></Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="flipbook" title="Flipbook">
                                        {' '}
                                        <Row>
                                            <Col>
                                                <CheckBoxControl title="Redirect" readOnly={false} value={redirect} change={setRedirect} />
                                            </Col>
                                            <Col>
                                                <CheckBoxControl
                                                    title="Save Tracking"
                                                    readOnly={false}
                                                    value={saveTracking}
                                                    change={setSaveTracking}
                                                />
                                            </Col>{' '}
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="youtube" title="Youtube">
                                        <Row>
                                            <TextInputControl
                                                title={'Video ID:'}
                                                placeHolder={''}
                                                readOnly={false}
                                                change={(input: string) => setYoutube({ ...youtube, video_id: input })}
                                                value={youtube.video_id}
                                            />
                                        </Row>
                                        <Row>
                                            <TextInputControl
                                                title={'Page Titel:'}
                                                placeHolder={''}
                                                readOnly={false}
                                                change={(input: string) => setYoutube({ ...youtube, page_title: input })}
                                                value={youtube.page_title}
                                            />
                                        </Row>
                                        <Row>
                                            <TextInputControl
                                                title={'Header Html:'}
                                                placeHolder={''}
                                                readOnly={false}
                                                change={(input: string) => setYoutube({ ...youtube, header_html: input })}
                                                value={youtube.header_html}
                                            />
                                        </Row>
                                        <Row>
                                            <TextInputControl
                                                title={'Footer Html:'}
                                                placeHolder={''}
                                                readOnly={false}
                                                change={(input: string) => setYoutube({ ...youtube, footer_html: input })}
                                                value={youtube.footer_html}
                                            />
                                        </Row>
                                        <Row>
                                            <TextInputControl
                                                title={'CSS Style:'}
                                                placeHolder={''}
                                                readOnly={false}
                                                change={(input: string) => setYoutube({ ...youtube, css_style: input })}
                                                value={youtube.css_style}
                                            />
                                        </Row>
                                        <Row>
                                            <TextInputControl
                                                title={'Comment:'}
                                                placeHolder={''}
                                                readOnly={false}
                                                change={(input: string) => setYoutube({ ...youtube, comment: input })}
                                                value={youtube.comment}
                                            />
                                        </Row>
                                        <Row>
                                            <NumberControl
                                                title={'Player Width:'}
                                                readOnly={false}
                                                change={(input: number) => setYoutube({ ...youtube, player_width: input })}
                                                value={youtube.player_width}
                                            />
                                        </Row>
                                        <Row>
                                            <NumberControl
                                                title={'Player Height:'}
                                                readOnly={false}
                                                change={(input: number) => setYoutube({ ...youtube, player_height: input })}
                                                value={youtube.player_height}
                                            />
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CheckBoxControl title="Redirect" readOnly={false} value={redirect} change={setRedirect} />
                                            </Col>
                                            <Col>
                                                <CheckBoxControl
                                                    title="Save Tracking"
                                                    readOnly={false}
                                                    value={saveTracking}
                                                    change={setSaveTracking}
                                                />
                                            </Col>{' '}
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button
                                                    onClick={async () => {
                                                        let result = await shortenYoutube(
                                                            stateContext.apiurl,
                                                            keycloakContext.keycloak.token ? keycloakContext.keycloak.token : 'dummy_token',
                                                            {
                                                                ...youtube,
                                                                lifespan: lifespanInS,
                                                                uses,
                                                                live_on: liveOn,
                                                                expires_on: expireOn,
                                                                redirect,
                                                                id,
                                                                tracking_id,
                                                                save_tracking: saveTracking,
                                                            }
                                                        );
                                                        if (result) {
                                                            if ('error' in result) {
                                                                setErrorMessage(result.error);
                                                            } else {
                                                                setResultUrl(result.url);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    Send
                                                </Button>
                                            </Col>
                                            <Col></Col>
                                            <Col></Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Row>

                    <Row>
                        <Card>
                            <Card.Header>Result</Card.Header>
                            <Card.Body>
                                {' '}
                                <TextInputControl
                                    title={'Result Url:'}
                                    placeHolder={''}
                                    readOnly={false}
                                    change={() => {}}
                                    value={resultUrl}
                                />
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
