import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';

import { GlobalCxt, IContext } from '../context/GlobalContext';
import DataTab from '../components/DataTab';
import UsedDataTab from '../components/UsedDataTab';
interface Props {}
export default function Stats() {
    const { keycloakContext, stateContext }: IContext = React.useContext(GlobalCxt);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const update = async () => {};
    useEffect(() => {
        (async () => {
            await update();
        })();
    }, []);
    return (
        <Container>
            {errorMessage !== '' ? (
                <Row>
                    <Col sm="2">
                        <h4>Error Message:</h4>
                    </Col>
                    <Col>
                        <p>{errorMessage}</p>
                    </Col>
                </Row>
            ) : (
                <Tabs>
                    <Tab eventKey="data" title="Current Data">
                        <DataTab />
                    </Tab>
                    <Tab eventKey="useddata" title="Used Data">
                        <UsedDataTab />
                    </Tab>
                </Tabs>
            )}
        </Container>
    );
}
