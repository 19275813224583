import React from 'react';
import { Button, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Logo from '../assets/header_logo.png';
import { GlobalCxt, IContext } from '../context/GlobalContext';

interface Props {
    projectTitle: string;
}

/**
 *
 * Header Column with tribe logo
 * @return {JSX.Element}
 */
export const Header = (props: Props): JSX.Element => {
    const { viewContext, keycloakContext, languageContext }: IContext = React.useContext(GlobalCxt);
    return (
        <header className="row row_header app_header ">
            <Navbar expand="lg">
                <Navbar.Brand className={'header_user_product_logo'}>
                    <Image
                        src={Logo}
                        className={'company_logo header_user_icon'}
                        onClick={() => {
                            viewContext.setView('dashboard');
                        }}
                    />
                    <h3
                        className={'header_user_title header_user_icon'}
                        onClick={() => {
                            viewContext.setView('dashboard');
                        }}
                    >
                        {props.projectTitle}
                    </h3>
                </Navbar.Brand>

                <Navbar.Toggle />
                <div className={'header_user_button_group'}>
                    <Nav.Link
                        style={{ color: 'white' }}
                        className={viewContext.view === 'stats' ? 'highlighted' : ''}
                        onClick={() => {
                            viewContext.setView('stats');
                        }}
                    >
                        Stats
                    </Nav.Link>
                </div>
                <div className={'header_user_button_group'}>
                    <Button disabled className={'header_user_status_button'}>
                        {languageContext.header.userStatus.text} {keycloakContext.keycloak.tokenParsed?.preferred_username}
                    </Button>
                    <Button
                        onClick={() => {
                            if (keycloakContext.keycloak.authenticated) {
                                keycloakContext.keycloak.logout({
                                    redirectUri:
                                        window.location.href === 'http://localhost:3000/' ? window.location.href : window.location.origin,
                                });
                            }
                        }}
                    >
                        {languageContext.header.logout.text}
                    </Button>
                </div>
            </Navbar>
        </header>
    );
};
