import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { GlobalCxt, IContext } from '../context/GlobalContext';
import { getUrlStats } from '../api/stats';
import { Return_select_short_urls } from '../../../backend/src/_generated/select_functions';
import TurboTable from './table/TurboTable';
interface Props {}
export default function DataTab() {
    const { keycloakContext, stateContext }: IContext = React.useContext(GlobalCxt);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [data, setData] = useState<Return_select_short_urls['short_urls']>([]);

    const update = async () => {
        const result = await getUrlStats(
            stateContext.apiurl,
            keycloakContext.keycloak.token ? keycloakContext.keycloak.token : 'dummy_token'
        );
        if (result) {
            if ('error' in result) {
                setErrorMessage(result.error);
            } else {
                setData(result);
            }
        }
    };
    useEffect(() => {
        (async () => {
            await update();
        })();
    }, []);
    return (
        <Container>
            {errorMessage !== '' ? (
                <Row>
                    <Col sm="2">
                        <h4>Error Message:</h4>
                    </Col>
                    <Col>
                        <p>{errorMessage}</p>
                    </Col>
                </Row>
            ) : (
                <>
                    <TurboTable
                        headerLabels={[
                            { label: 'short_id' },
                            { label: 'created_on' },
                            { label: 'live_on' },
                            { label: 'expires_on' },
                            { label: 'lifespan' },
                            { label: 'uses' },
                            { label: 'called' },
                            { label: 'redirect' },
                            { label: 'tracking_id' },
                            { label: 'url_type' },
                            { label: 'save_tracking' },
                            { label: 'statistic' },
                        ]}
                        rows={data.map((e) => {
                            return [
                                <div>{e.id}</div>,
                                <div>{e.created_on}</div>,
                                <div>{e.live_on}</div>,
                                <div>{e.expires_on}</div>,
                                <div>{e.lifespan}</div>,
                                <div>{e.uses}</div>,
                                <div>{e.called}</div>,
                                <div>{e.redirect}</div>,
                                <div>{e.tracking_id}</div>,
                                <div>{e.url_type}</div>,
                                <div>{e.save_tracking}</div>,
                                <TurboTable
                                    headerLabels={[
                                        { label: 'id' },
                                        { label: 'user_agent' },
                                        { label: 'referrer' },
                                        { label: 'ip_address' },
                                        { label: 'accept_language' },
                                        { label: 'browser_name' },
                                        { label: 'browser_version' },
                                        { label: 'browser_plattform' },
                                        { label: 'ip_country' },
                                        { label: 'ip_region' },
                                        { label: 'ip_city' },
                                        { label: 'ip_isp' },
                                    ]}
                                    rows={
                                        e.statistic !== null
                                            ? e.statistic.map((e) => {
                                                  return [
                                                      <div>{e.id}</div>,
                                                      <div>{e.user_agent}</div>,
                                                      <div>{e.referrer}</div>,
                                                      <div>{e.ip_address}</div>,
                                                      <div>{e.accept_language}</div>,
                                                      <div>{e.browser_name}</div>,
                                                      <div>{e.browser_version}</div>,
                                                      <div>{e.browser_plattform}</div>,
                                                      <div>{e.ip_country}</div>,
                                                      <div>{e.ip_region}</div>,
                                                      <div>{e.ip_city}</div>,
                                                      <div>{e.ip_isp}</div>,
                                                  ];
                                              })
                                            : []
                                    }
                                />,
                            ];
                        })}
                    />
                </>
            )}
        </Container>
    );
}
