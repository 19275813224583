import { ILanguage } from '../../language';
export const LANG_Header: ILanguage[] = [
    {
        code: 'header_logout',
        base: 'Ausloggen',
        translation: 'Logout',
    },
    {
        code: 'header_userStatus',
        base: 'Benutzer:',
        translation: 'User:',
    },
];
