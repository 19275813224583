import { ILanguage } from '../language';
import { LANG_Buttons } from './Lang_Buttons';

import { LANG_Footer } from './structure/lang_Footer';
import { LANG_Header } from './structure/lang_Header';

export let englishLanguageSet: ILanguage[] = ([] as ILanguage[]).concat(
    //components
    LANG_Buttons,

    //structure
    LANG_Footer,
    LANG_Header
);
