import React from 'react';

import { GlobalCxt, IContext } from '../context/GlobalContext';
import { View } from '../context/GlobalViewContext';
import Dashboard from '../views/Dashboard';
import Stats from '../views/Stats';

interface Props {}
interface State {}

/**
 * A layout component which allows you to switch between the two important views meeting and server
 *
 * @export
 * @param {Props} props
 * @return {*}
 */
export default class Body extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    showView(globalView: View) {
        switch (globalView) {
            case 'dashboard': {
                return <Dashboard />;
            }
            case 'stats': {
                return <Stats />;
            }
        }
    }
    static contextType = GlobalCxt;
    render() {
        let context: IContext = this.context;
        return (
            <>
                <div className="app_body">
                    <div className={`app_body_container`}>{this.showView(context.viewContext.view)}</div>
                </div>
            </>
        );
    }
}
