import { EventEmitter } from 'events';
import { AppLanuageManager } from 'ttmultilanguage';

import { englishLanguageSet } from './english/english';

export class MyLanguageManager extends AppLanuageManager {
    event = new EventEmitter();

    static create() {
        return new MyLanguageManager();
    }

    changeLanguage = (lang: string) => {
        this.activate(lang);
        this.event.emit('changed');
    };
}

export const LANGUAGE_MANAGER = MyLanguageManager.create();
export function loadTranslations() {
    LANGUAGE_MANAGER.setTranslations('en', englishLanguageSet);
}

export type Language = 'de' | 'en';
declare global {
    interface Window {
        _manager: MyLanguageManager;
    }
}

window._manager = LANGUAGE_MANAGER;
export interface ILanguage {
    code: string;
    base: string;
    translation: string;
}
