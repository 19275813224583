import { ILanguage } from '../language';

export const LANG_Buttons: ILanguage[] = [
    {
        code: 'buttons_cancel',
        base: 'Abbrechen',
        translation: 'Cancel',
    },
    {
        code: 'buttons_confirm',
        base: 'Bestätigen',
        translation: 'Confirm',
    },
    {
        code: 'buttons_save_changes',
        base: 'Änderungen speichern',
        translation: 'Save changes',
    },
    {
        code: 'buttons_reset_password',
        base: 'Passwort zurücksetzen',
        translation: 'Reset password',
    },
    {
        code: 'buttons_search',
        base: 'Suche',
        translation: 'Search',
    },
    {
        code: 'buttons_buy',
        base: 'Kaufen',
        translation: 'Buy',
    },
    {
        code: 'buttons_addClient',
        base: 'Applikation hinzufügen',
        translation: 'Add Client',
    },
    {
        code: 'buttons_editClient',
        base: 'Applikation ändern',
        translation: 'Edit client',
    },
    {
        code: 'buttons_addUser',
        base: 'Benutzer hinzufügen',
        translation: 'Add user',
    },
    {
        code: 'buttons_editUser',
        base: 'Benutzer ändern',
        translation: 'Edit user',
    },
    {
        code: 'buttons_add',
        base: 'Anlegen',
        translation: 'Add',
    },
    {
        code: 'buttons_edit',
        base: 'Ändern',
        translation: 'Edit',
    },
    {
        code: 'buttons_delete',
        base: 'Löschen',
        translation: 'Delete',
    },
    {
        code: 'buttons_goToApp',
        base: 'Applikation öffnen',
        translation: 'Open client',
    },
];
