import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
interface Props {
    title: string;
    placeHolder: string;
    /**
     * the maximum allowed length, defaults to 0 (unlimited)
     *
     * @type {number}
     * @memberof Props
     */
    maxLength?: number;
    value?: string;
    readOnly: boolean;
    toolTip?: string;

    change: (val: string, sender?: TextInputControl) => void;
    isRequired?: boolean;
    /**
     * If true the content of the field will be masked
     *
     * @type {boolean}
     * @memberof Props
     */
    asPassword?: boolean;
}
interface State {
    isInvalid: boolean;
}
/**
 *
 *
 * @class TextInputControl
 * @extends {React.Component<Props, State>}
 * @param title string String which is displayed in the label
 * @param placeHolder string String which is displayed in the label
 * @param value boolean Value of the button to be displayed
 * @param readOnly boolean Blocks change of values
 * @param change (val: boolean) => void SetState function for the value
 * @param asPassword boolean (Optional) If true the content of the field will be masked
 * @param isRequired boolean (Optional) Validation of the input
 * @description Component for an inline formatted input element with a label
 */
class TextInputControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isInvalid: props.value === '' ? true : false,
        };
    }

    render() {
        return (
            <Form.Group as={Row}>
                <Col sm={3}>
                    <Form.Label className="launcher_form_label">{this.props.title}</Form.Label>
                </Col>

                <Col sm={8}>
                    <Form.Control
                        readOnly={this.props.readOnly}
                        style={this.props.readOnly ? { background: '#95989a' } : undefined}
                        type={this.props.asPassword ? 'password' : 'text'}
                        placeholder={this.props.placeHolder}
                        value={this.props.value}
                        onChange={(ev) => {
                            this.setState({ isInvalid: ev.currentTarget.value === '' ? true : false });
                            if (!this.props.maxLength || this.props.maxLength === 0 || ev.currentTarget.value.length <= this.props.maxLength) {
                                this.props.change(ev.currentTarget.value, this);
                            }
                        }}
                        isInvalid={!this.props.isRequired ? false : this.state.isInvalid}
                        autoComplete="new-password"
                    />
                </Col>
            </Form.Group>
        );
    }
}

export default TextInputControl;
